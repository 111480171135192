import * as d3 from 'd3'
import moment, { Moment } from 'moment'
import createTheme from 'src/theme'
import { DATE_FORMAT, isBlankDate, isEndOfDay } from 'src/utils/dateUtils'
const { colors } = createTheme()

const WEEKS_PER_MONTH = 4
const WEEKS_PER_YEAR = WEEKS_PER_MONTH * 12
const CORNERS_MIN_WIDTH = 10
const BLANK_DATE_WIDTH = 3

interface ChartConfig {
  startOfYear: Moment
  svg: any
  width: number
  height: number
  tooltip: any
  settings: any
}

export const dataAttrs = {
  container: () => 'gantt-container',
  months: () => 'gantt-months-titles',
  currentDate: () => 'gantt-current-date',
  timelinePeriod: () => 'gantt-timeline-period'
}

const datePosition = (c: ChartConfig, date: Moment) => {
  if (date.isBefore(c.startOfYear.clone().startOf('year'))) {
    return 0
  }

  if (date.isAfter(c.startOfYear.clone().endOf('year'))) {
    return c.width
  }

  const widthPerDay = c.width / 365
  const dayOfYear = isEndOfDay(date) ? date.dayOfYear() + 1 : date.dayOfYear()

  return dayOfYear * widthPerDay
}

const outerBorder = (c: ChartConfig) => {
  const padding = c.settings.gridPadding

  c.svg
    .append('rect')
    .attr('x', 0.8)
    .attr('width', c.width - 1.6)
    .attr('height', c.height - padding.top - padding.bottom)
    .attr('y', padding.top)
    .attr('rx', 8)
    .attr('ry', 8)
    .style('fill', 'transparent')
    .style('stroke', c.settings.colors.border)
    .style('stroke-width', c.settings.border.width)
}

const verticalGrid = (c: ChartConfig) => {
  const padding = c.settings.gridPadding
  const widthPerWeek = c.width / WEEKS_PER_YEAR

  for (let week = 1; week < WEEKS_PER_YEAR; week += 1) {
    const x = widthPerWeek * week

    let stroke = c.svg
      .append('line')
      .style('stroke', c.settings.colors.verticalLine)
      .style('stroke-width', c.settings.stroke.width)

    if (week % WEEKS_PER_MONTH !== 0) {
      stroke = stroke.style('stroke-dasharray', c.settings.stroke.dash)
    }

    stroke
      .attr('x1', x)
      .attr('y1', padding.top)
      .attr('x2', x)
      .attr('y2', c.height - padding.bottom)
  }
}

const horizontalGrid = (c: ChartConfig, absences: IAbsence[]) => {
  const padding = c.settings.gridPadding
  const lineNumber = absences.length - 1
  if (lineNumber < 1) {
    return
  }

  for (let line = 0; line < lineNumber; line += 1) {
    const y = c.settings.row.height * (line + 1) + padding.top
    c.svg
      .append('line')
      .style('stroke', c.settings.colors.horizontalLine)
      .style('stroke-width', c.settings.stroke.width)
      .attr('x1', 0)
      .attr('y1', y)
      .attr('x2', c.width)
      .attr('y2', y)
  }
}

const monthTitles = (c: ChartConfig) => {
  const widthPerWeek = c.width / 12

  const start = c.startOfYear.clone().startOf('year')
  const end = c.startOfYear.clone().endOf('year')

  for (let month = start; month.isBefore(end); month.add(1, 'month')) {
    const title = month.format('MMM ‘YY')

    c.svg
      .append('text')
      .attr('data-testid', dataAttrs.months())
      .attr('x', month.month() * widthPerWeek + widthPerWeek / 2)
      .attr('y', 10)
      .attr('font-size', c.settings.fontSize)
      .attr('fill', c.settings.colors.header)
      .attr('text-anchor', 'middle')
      .text(title)
  }
}

const currentDate = (c: ChartConfig, date: Moment) => {
  if (date.year() !== c.startOfYear.year()) {
    return
  }

  const padding = c.settings.gridPadding
  const x = datePosition(c, date)

  c.svg
    .append('line')
    .style('stroke', c.settings.colors.currentDate)
    .style('stroke-width', 1)
    .style('stroke-dasharray', c.settings.stroke.dash)
    .attr('x1', x)
    .attr('y1', padding.top)
    .attr('x2', x)
    .attr('y2', c.height - padding.bottom)

  let xShift = 0

  if (date.month() === 0) {
    xShift = -25
  }

  if (date.month() === 11) {
    xShift = 25
  }

  c.svg
    .append('text')
    .attr('data-testid', dataAttrs.currentDate())
    .attr('x', x - xShift)
    .attr('y', c.height - 2)
    .attr('font-size', c.settings.fontSize)
    .attr('fill', c.settings.colors.currentDate)
    .attr('text-anchor', 'middle')
    .text(date.format(DATE_FORMAT))
}

const absenceRows = (c: ChartConfig, absences: IAbsence[]) =>
  absences.forEach((absence, index) =>
    absence.timeline
      .filter(period => period.type !== 'AtWork')
      .forEach(period => timelinePeriod(c, period, index))
  )

const dateRange = (period: IAbsenceTimelinePeriod) => {
  const start = moment(period.startDate).utc().format(DATE_FORMAT)
  const end = isBlankDate(moment(period.endDate))
    ? 'Pending'
    : moment(period.endDate).utc().format(DATE_FORMAT)
  return `${start} - ${end}`
}

const createDiagonalStripes = (c: ChartConfig, color) => {
  const patternId = `diagonalStripes-${color}`

  if (!document.getElementById(patternId)) {
    const pattern = c.svg
      .select('defs')
      .append('pattern')
      .attr('id', patternId)
      .attr('width', 4)
      .attr('height', 4)
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('patternTransform', 'rotate(45)')

    pattern
      .selectAll('line')
      .data([0, 20])
      .join('line')
      .attr('x1', d => d)
      .attr('y1', 0)
      .attr('x2', d => d)
      .attr('y2', 40)
      .attr('stroke-width', 4)
      .attr('stroke', color)
  }

  return `url(#${patternId})`
}

const timelinePeriod = (
  c: ChartConfig,
  period: IAbsenceTimelinePeriod,
  position: number
) => {
  const start = datePosition(c, moment(period.startDate).utc())
  const end = isBlankDate(moment(period.endDate))
    ? start + BLANK_DATE_WIDTH
    : datePosition(c, moment(period.endDate).utc())

  // add padding to emulate one-side rounded rectangles
  const x1 = start === 0 ? start - CORNERS_MIN_WIDTH : start
  const x2 = end === c.width ? end + CORNERS_MIN_WIDTH : end

  const y =
    position * c.settings.row.height +
    c.settings.gridPadding.top +
    c.settings.row.padding

  const { blocks } = c.settings.colors
  const { fill, stroke, stripesColor } = blocks[period.type] || blocks.Default

  const svgPeriod = c.svg
    .append('rect')
    .attr('data-testid', dataAttrs.timelinePeriod())
    .attr('aria-label', period.type)
    .attr('x', x1)
    .attr('y', y)
    .attr('width', x2 - x1)
    .attr('height', c.settings.row.height - c.settings.row.padding * 2)
    .attr('rx', x2 - x1 > CORNERS_MIN_WIDTH ? 4 : 2)
    .on('mouseover', d => {
      c.tooltip.style('visibility', 'hidden')

      const shiftX =
        (d.view?.outerWidth || 0) - d.clientX < 200
          ? 150
          : c.settings.tooltip.shiftX

      c.tooltip
        .style('top', d.clientY + c.settings.tooltip.shiftY + 'px')
        .style('left', d.clientX - shiftX + 'px')
        .style('visibility', 'visible')
        .text(dateRange(period))
    })
    .on('mouseout', () => c.tooltip.style('visibility', 'hidden').text(''))
    .style('opacity', 0)
    .transition()
    .duration(500)
    .style('opacity', 1)

  if (stroke) {
    svgPeriod
      .style('stroke', stroke.color)
      .style('stroke-width', stroke.width)
      .style('stroke-dasharray', stroke.dash)
  }

  if (stripesColor) {
    svgPeriod
      .attr('fill', createDiagonalStripes(c, stripesColor))
      .style('stroke', stripesColor)
  } else {
    svgPeriod.style('fill', fill)
  }
}

export default (args: any, parentContainer: any) => {
  const { absences, selectedYear, tooltipRef } = args
  const date = moment()

  const settings = {
    colors: {
      header: colors.dark60,
      verticalLine: colors.dark10,
      horizontalLine: colors.dark20,
      border: colors.dark20,
      currentDate: colors.main90,
      blocks: {
        Default: { fill: colors.sub100, stroke: null },
        AtWork: { fill: colors.sub100, stroke: null },
        RampBack: {
          stroke: null,
          stripesColor: colors.dodgerBlue
        },
        OnLeave: { fill: colors.purple, stroke: null },
        Unknown: {
          fill: colors.main10,
          stroke: { color: colors.main100, width: 1, dash: '1.5' }
        },
        PendingEndDate: {
          fill: colors.purple,
          stroke: null
        }
      }
    },
    gridPadding: { top: 20, bottom: 20 },
    stroke: { width: 1, dash: 1.5 },
    border: { width: 1 },
    row: { height: 34, padding: 11 },
    tooltip: {
      shiftX: 20,
      shiftY: 20
    },
    fontSize: 10
  }

  const parentRect = parentContainer
    ? parentContainer.getBoundingClientRect()
    : { width: 0, height: 0 }

  const width: number = parentRect.width
  const height: number =
    absences.length * settings.row.height +
    settings.gridPadding.top +
    settings.gridPadding.bottom

  const tooltip = d3.select(tooltipRef.current)

  const svg: any = d3
    .select(parentContainer)
    .append('svg')
    .attr('data-testid', dataAttrs.container())
    .attr('width', width)
    .attr('height', height)

  svg.append('defs')

  const config = {
    startOfYear: moment(selectedYear, 'YYYY'),
    svg,
    width,
    height,
    tooltip,
    settings
  }

  verticalGrid(config)
  horizontalGrid(config, absences)
  outerBorder(config)
  monthTitles(config)
  absenceRows(config, absences)
  currentDate(config, date)
}
