import first from 'lodash.first'
import React, {
  Fragment,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { withDialogManager } from 'src/components/DialogManager'
import withQueries from 'src/components/HOC/withQueries'
import useRedirectByUserRole from 'src/components/hooks/useRedirectByUserRole'
import LoadingSpinner from 'src/components/LoadingSpinner'
import { withToastManager } from 'src/components/ToastManager'
import SharedContext from 'src/contexts/SharedContext'
import ProfileForm from 'src/features/UserProfile/components/ProfileForm'
import UserProfileContext, {
  IUserProfileContext
} from 'src/features/UserProfile/Context'
import useSessions, { IUseSessions } from 'src/graphql/hooks/useSessions'
import {
  IWithDialogManager,
  IWithQueriesProps,
  IWithToastManager
} from 'src/react-app-env'
import {
  createLeaveRoute,
  errorPageRoute,
  userProfileRoute,
  welcomeRoute
} from 'src/routes/constants'
import { ManagerActivationSource } from 'src/types/ManagerActivationSource'
import { SECTION_NOTIFICATION_SETTINGS, SECTION_USER } from 'src/utils/anchors'
import { isPublishedOrBabyArrivedLeave } from 'src/utils/leaveStatusUtils'
import { closePlanLocalStorageHook } from 'src/utils/ls'
import { isPathnameLike } from 'src/utils/routeUtils'
import { replaceSpecialSymbols } from 'src/utils/stringUtils'
import styled, { css } from 'styled-components'
import Page from './components/UserProfilePage'

interface IProps
  extends IWithQueriesProps,
    IWithToastManager,
    IWithDialogManager {
  showsFormOnly?: boolean
  onUserUpdated: (user: IUser) => void
}

const ProfileFormWrapper = styled(ProfileForm)`
  margin-top: 16px;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 640px;
        `
      : css`
          width: 100%;
        `}
`

const TextDialog = styled(ReactMarkdown)`
  font-weight: normal;
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};
  white-space: pre-wrap;
`

export const UserProfileContainer = React.memo((props: IProps) => {
  const { dialogManager, queries, showsFormOnly, onUserUpdated, toastManager } =
    props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { customerConfig } = useContext(SharedContext)

  const [searchParams] = useSearchParams()

  const [user, setUser] = useState(null)
  const [fieldErrors, setFieldErrors] = useState({})
  const [closingOutPlan, setClosingOutPlan] = useState(false)
  const [leave, setLeave] = useState<ILeave>(null)
  const [tpaSyncing, setTpaSyncing] = useState<boolean>(null)
  const [planHistory, setPlanHistory] = useState<ILeave[]>([])
  const [isSessionsSectionVisible, setIsSessionsSectionVisible] =
    useState(false)
  const [sessions, setSessions] = useState([])
  const [revokingSessionsId, setRevokingSessionsId] = useState([])

  useRedirectByUserRole({ navigate, allowedRole: 'employee' })

  const managerActivationSource = useMemo(() => {
    const source = searchParams.get('source')
    switch (source) {
      case 'journey':
        return ManagerActivationSource.Journey
      case 'reminder':
        return ManagerActivationSource.Reminder
      default:
        return ManagerActivationSource.Organic
    }
  }, [searchParams])

  useEffect(() => {
    fetchMe()
    if (!showsFormOnly) {
      fetchLeaveWithDatesAndMetadata()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showsFormOnly])

  useEffect(() => {
    if (sessions.length === 0) {
      setRevokingSessionsId([])
    }
  }, [sessions])

  useEffect(() => {
    if (onUserUpdated) {
      onUserUpdated(user)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const skipFetchingSessions: boolean =
    !isSessionsSectionVisible && sessions.length === 0
  const sessionsResult: IUseSessions = useSessions(skipFetchingSessions)
  if (sessionsResult.sessions.length !== 0 && sessions.length === 0) {
    setSessions(sessionsResult.sessions)
  }

  const isFieldConfirmed = (type: string): boolean => {
    if (!Array.isArray(user.confirmations)) {
      return false
    }

    const uc = user.confirmations.filter((c: IConfirmation) => c.type === type)
    const confirmation: IConfirmation = first(uc)

    if (!confirmation) {
      return false
    }
    return !!confirmation.confirmedAt
  }

  const showRemoveFieldPrompt = (
    onConfirm: () => void,
    onCancel: () => void,
    removeButtonTitleKey: string
  ) => {
    if (isPathnameLike(location.pathname, userProfileRoute)) {
      navigate(userProfileRoute + '/' + SECTION_USER)
    }

    dialogManager.add({
      title: t('userProfile.removeContactDialog.title'),
      children: t('userProfile.removeContactDialog.body'),
      ariaText: t(
        `userProfile.removeContactDialog.ariaText${removeButtonTitleKey}`
      ),
      onUserClosed: onCancel,
      buttons: [
        {
          title: t('userProfile.removeContactDialog.buttonAdjustSettings'),
          appearance: 'cancel',
          onClick: () => {
            onCancel()
            navigate(userProfileRoute + '/' + SECTION_NOTIFICATION_SETTINGS)
          },
          order: 1
        },
        {
          title: t(
            `userProfile.removeContactDialog.buttonRemove${removeButtonTitleKey}`
          ),
          appearance: 'destructive',
          onClick: onConfirm,
          order: 2
        }
      ]
    })
  }

  const updatePersonalEmail = (personalEmail: string) => {
    if (personalEmail === '' && user.personalEmail.length) {
      if (!isFieldConfirmed('PersonalEmail')) {
        updateUser({ personalEmail }, false)
      } else {
        showRemoveFieldPrompt(
          () => {
            updateUser({ personalEmail }, false)
          },
          () => {
            revertUserState()
          },
          'Email'
        )
      }
    } else {
      updateUser({ personalEmail }, true)
    }
  }

  const updatePhoneNumber = (value: string) => {
    const phoneNumber = value === '' ? '+1' : '+' + replaceSpecialSymbols(value)
    if (phoneNumber === '+1' && user.phoneNumber.length) {
      if (!isFieldConfirmed('PhoneNumber')) {
        updateUser({ phoneNumber: '' }, false)
      } else {
        showRemoveFieldPrompt(
          () => {
            updateUser({ phoneNumber: '' }, false)
          },
          () => {
            revertUserState()
          },
          'PhoneNumber'
        )
      }
    } else {
      updateUser({ phoneNumber }, true)
    }
  }

  const sendPersonalEmailConfirmationCode = (value: string) => {
    const code = replaceSpecialSymbols(value)
    updateConfirmationCode(code, 'PersonalEmail')
  }

  const sendPhoneNumberConfirmationCode = (value: string) => {
    const code = replaceSpecialSymbols(value)
    updateConfirmationCode(code, 'PhoneNumber')
  }

  const updateConfirmationCode = async (code: string, kind: string) => {
    setFieldErrors({})
    const aUser: IUser = await queries.updateConfirmationCode(code, kind, {
      badRequest: onConfirmationBadRequest,
      unhandled: parseFieldErrors
    })
    setUser(aUser)
  }

  const resendConfirmationCode = async (kind: string) => {
    const confirmation: IConfirmation =
      await queries.resendConfirmationCode(kind)
    const aUser: IUser = { ...user }
    const confirmations: IConfirmation[] = user.confirmations.filter(
      (conf: IConfirmation) => conf.type !== confirmation.type
    )
    confirmations.push(confirmation)
    aUser.confirmations = confirmations
    setUser(aUser)
    toastManager.addInfo(t('userProfile.codeHasBeenSent'))
  }

  const updateNotificationSettings = async (
    notificationSettings: INotificationSettingsInput[]
  ) => {
    updateUser({ notificationSettings }, false)
  }

  const updateUser = async (
    changes: IUserInput,
    shouldShowToastCodeSent: boolean
  ) => {
    setFieldErrors({})

    const aUser: IUser = await queries.updateUser(changes, {
      badRequest: parseFieldErrors,
      unhandled: parseFieldErrors
    })

    if (aUser) {
      setUser({ ...aUser, random: Date.now() })
      setFieldErrors({})
      if (shouldShowToastCodeSent) {
        toastManager.addInfo(t('common.accessibilityText.verificationCodeSent'))
      }
    } else {
      toastManager.addError(t('common.somethingWentWrongTryAgain'))
    }
  }

  const fetchMe = async () => {
    try {
      const aUser: IUser = await queries.fetchMe({
        notFound: () => {
          navigate(welcomeRoute)
        }
      })
      setUser(aUser)
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }

  const fetchLeaveWithDatesAndMetadata = async (fromCache = false) => {
    try {
      const aLeave: ILeave = await queries.fetchLeaveWithDatesAndMetadata({
        fetchPolicy: fromCache ? 'cache-first' : 'network-only',
        notFound: () => {
          setLeave(null)
        }
      })
      if (aLeave) {
        setLeave(aLeave)
        if (tpaSyncing === null) {
          setTpaSyncing(aLeave.tpa?.syncing)
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }

  const fetchLSuspendedLeaves = async () => {
    try {
      const leaves: ILeave[] = await queries.fetchSuspendedLeaves({
        fetchPolicy: 'network-only',
        notFound: () => {
          setPlanHistory([])
        }
      })
      if (leaves) {
        setPlanHistory(leaves)
      }
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }

  const onSetTpaSyncing = async () => {
    const result = await queries.setTpaSyncing(!tpaSyncing, {})
    if (result) {
      setTpaSyncing(!tpaSyncing)
    }
    await fetchLeaveWithDatesAndMetadata()
  }

  const showPrompt = () => {
    dialogManager.add({
      title: t('claimData.dialog.turnOff.title'),
      ariaText: t('claimData.dialog.turnOff.ariaText'),
      buttons: [
        {
          title: t('common.cancel'),
          appearance: 'cancel',
          onClick: (): void => undefined,
          order: 1
        },
        {
          title: t('claimData.dialog.turnOff.button'),
          appearance: 'destructive',
          onClick: onSetTpaSyncing,
          order: 2
        }
      ],
      children: <TextDialog>{t('claimData.dialog.turnOff.body')}</TextDialog>
    })
  }

  const toggleTpaSyncing = () => {
    try {
      if (
        customerConfig.userProfile.tpaSyncSection.showPromptTurnOff(tpaSyncing)
      ) {
        showPrompt()
      } else {
        onSetTpaSyncing()
      }
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }

  const activateManagerForLeave = async () => {
    try {
      await queries.activateManagerForLeave(managerActivationSource, {
        fetchPolicy: 'network-only'
      })
      await fetchLeaveWithDatesAndMetadata(true)
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }

  const closeOutPlan = async (
    shouldAddNotifyManagerField: boolean,
    notifyManager: boolean
  ) => {
    setClosingOutPlan(true)

    const result: boolean = await queries.suspendLeave(
      shouldAddNotifyManagerField,
      notifyManager,
      {
        notFound: () => {
          onClosingOutPlanError()
        },
        badRequest: () => {
          onClosingOutPlanError()
        },
        unhandled: () => {
          onClosingOutPlanError()
        }
      }
    )

    setClosingOutPlan(false)
    if (result) {
      closePlanLocalStorageHook()
      navigate(createLeaveRoute)
    }
  }

  const onClosingOutPlanError = () => {
    toastManager.addError(t('common.somethingWentWrong'))

    fetchLeaveWithDatesAndMetadata()
  }

  const onConfirmationBadRequest = async (er: any) => {
    const aUser: IUser = await queries.fetchMe({ fetchPolicy: 'network-only' })
    setUser(aUser)
    parseFieldErrors(er)
  }

  const parseFieldErrors = (er: any) => {
    try {
      const {
        extensions: { fields },
        message
      } = er
      if (!fields) {
        return
      }
      const newFieldErrors: any = {}
      fields.forEach((field: string) => {
        newFieldErrors[field] = message
      })
      setFieldErrors(newFieldErrors)
    } catch (_e) {
      setFieldErrors({ random: Date.now() })
      toastManager.addError(t('common.somethingWentWrongPleaseTryAgainLater'))
    }
  }

  const revertUserState = () => {
    setUser({ ...user, random: Date.now() })
  }

  const onSessionSectionAppeared = () => {
    if (!isSessionsSectionVisible) {
      setIsSessionsSectionVisible(true)
      fetchLSuspendedLeaves()
    }
  }

  const markSessionIsRevoking = (id: string) => {
    setRevokingSessionsId([...revokingSessionsId, id])
  }

  const markAllSessionsIsRevoking = () => {
    setRevokingSessionsId(sessions.map((s: ISession) => s.id))
  }

  const unmarkAllSessionsIsRevoking = () => {
    setRevokingSessionsId([])
  }

  const revokeSession = async (sessionId: string) => {
    markSessionIsRevoking(sessionId)
    const result: boolean = await queries.revokeSession(sessionId, {
      unhandled: () => {
        setSessions([])
      }
    })
    unmarkAllSessionsIsRevoking()
    if (result) {
      const newSessions: ISession[] = sessions.filter(
        (session: ISession) => session.id !== sessionId
      )
      setSessions(newSessions)
      toastManager.addInfo(t('common.sessionHasBeenRevoked'))
    } else {
      toastManager.addError(t('common.somethingWentWrong'))
    }
  }

  const revokeAllSessions = async () => {
    markAllSessionsIsRevoking()
    const result: boolean = await queries.revokeAllSessions({
      unhandled: () => {
        setSessions([])
      }
    })
    unmarkAllSessionsIsRevoking()
    if (result) {
      const newSessions: ISession[] = sessions.filter(
        (session: ISession) => session.current
      )
      setSessions(newSessions)
      toastManager.addInfo(t('common.allSessionsHaveBeenRevoked'))
    } else {
      toastManager.addError(t('common.somethingWentWrong'))
    }
  }

  const removeLeave = useCallback(
    async (id: string) => {
      const onFailed = () => {
        toastManager.addError(t('common.somethingWentWrong'))
      }

      const result: boolean = await queries.removeLeave(id, {
        badRequest: onFailed,
        unhandled: onFailed
      })

      if (!result) {
        onFailed()
        return
      }

      toastManager.addInfo(t('common.planHasBeenRemoved'))

      const filteredLeaves = planHistory.filter((l: ILeave) => l.id !== id)
      setPlanHistory(filteredLeaves)
    },
    [queries, toastManager, t, planHistory]
  )

  const contextValue: IUserProfileContext = {
    user,
    leave,
    tpaSyncing,
    fieldErrors,
    hasPlanToCloseOut: !!leave && isPublishedOrBabyArrivedLeave(leave.status),
    hasPlanHistory: !!planHistory.length,
    updatePersonalEmail,
    updatePhoneNumber,
    sendPersonalEmailConfirmationCode,
    sendPhoneNumberConfirmationCode,
    resendConfirmationCode,
    updateNotificationSettings,
    closeOutPlan,
    toggleTpaSyncing,
    activateManagerForLeave,
    onSessionSectionAppeared,
    revokeSession,
    revokeAllSessions,
    sessions,
    sessionsResult,
    revokingSessionsId,
    planHistory,
    removeLeave,
    showTpaLastUpdate: customerConfig.userProfile.tpaSyncSection.showLastUpdate
  }

  const content: ReactNode = showsFormOnly ? (
    <ProfileFormWrapper
      user={user}
      resendConfirmationCode={resendConfirmationCode}
      sendPersonalEmailConfirmationCode={sendPersonalEmailConfirmationCode}
      sendPhoneNumberConfirmationCode={sendPhoneNumberConfirmationCode}
      updatePersonalEmail={updatePersonalEmail}
      updatePhoneNumber={updatePhoneNumber}
      fieldErrors={fieldErrors}
    />
  ) : (
    <Page />
  )

  return (
    <UserProfileContext.Provider value={contextValue}>
      <Fragment>
        {content}
        {closingOutPlan && <LoadingSpinner fullScreen fadesIn />}
      </Fragment>
    </UserProfileContext.Provider>
  )
})

UserProfileContainer.displayName = 'UserProfileContainer'

export default withDialogManager(
  withToastManager(withQueries(UserProfileContainer))
)
