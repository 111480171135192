import React from 'react'
import styled from 'styled-components'
import { isFirefox } from 'src/utils/deviceTypes'
import { Icon } from 'src/UIKit'
import { useTranslation } from 'react-i18next'

interface IProps {
  className?: string
}

export const Button = styled.button`
  border-radius: 16px;
  border: 2px solid transparent;
  padding: 8px;

  &:focus {
    outline: none;
    border: 2px solid ${props => props.theme.colors.main100};
  }
`

const PrintIcon = styled(Icon)`
  width: 20px;
  height: 18px;
  cursor: pointer;
`

const PrintButton = React.memo((props: IProps) => {
  const { t } = useTranslation()

  return (
    <Button
      aria-label={t('common.accessibilityText.print')}
      className={props.className}
      onClick={() => {
        if (isFirefox()) {
          window.print()
        } else {
          try {
            document.execCommand('print', false, null)
          } catch (_e) {
            window.print()
          }
        }
      }}
    >
      <PrintIcon name={'print'} />
    </Button>
  )
})

PrintButton.displayName = 'PrintButton'

export default PrintButton
