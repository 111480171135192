import { ICustomerConfig } from 'src/config/customers/config'
import { VIEW_NEXT_STEPS_NOTICE_KEY } from './ls'

interface IViewNextStepsNotice {
  canBeShown: boolean
}

const getViewNextStepsNotice = (): IViewNextStepsNotice => {
  try {
    const viewNextStepsNotice: IViewNextStepsNotice = JSON.parse(
      localStorage.getItem(VIEW_NEXT_STEPS_NOTICE_KEY)
    )
    if (!viewNextStepsNotice) {
      return { canBeShown: true }
    }

    return viewNextStepsNotice
  } catch (_e) {
    return { canBeShown: true }
  }
}

const setViewNextStepsNotice = (viewNextStepsNotice: IViewNextStepsNotice) => {
  localStorage.setItem(
    VIEW_NEXT_STEPS_NOTICE_KEY,
    JSON.stringify(viewNextStepsNotice)
  )
}

const setCanBeShown = (canBeShown: boolean) => {
  const viewNextStepsNotice: IViewNextStepsNotice = getViewNextStepsNotice()
  viewNextStepsNotice.canBeShown = canBeShown
  setViewNextStepsNotice(viewNextStepsNotice)
}

const getCanBeShown = (): boolean => getViewNextStepsNotice().canBeShown

const shouldShowNextStepsNotice = (
  leave: ILeave,
  customerConfig: ICustomerConfig
) => customerConfig.leave.timeline.showNextStepsNotice(leave)

export { setCanBeShown, getCanBeShown, shouldShowNextStepsNotice }
