import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getAbsencePeriodDurationString } from 'src/utils/absences'
import { getTodayYesterdayOrDate, isBlankDate } from 'src/utils/dateUtils'
import styled, { css } from 'styled-components'
import TimelineArrow from 'src/features/Timeline/components/common/TimelineArrow'
import { IAbsenceTimelineItem } from 'src/react-app-env'
import i18n from 'i18next'
import createTheme from 'src/theme'
import { getDaysBetweenDates } from 'src/features/Timeline/components/vertical/LeaveDurationPickers/methods'
const { colors } = createTheme()

interface IProps {
  period: IAbsenceTimelineItem
}

const containerMixin = css`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  line-height: 140%;
  padding: 6px 8px;
`

const ContainerDefault = styled.div`
  ${containerMixin}
  color: ${props => props.theme.colors.purple};
  border: 1px solid ${props => props.theme.colors.purple};
`

const ContainerOnLeave = styled.div<{ $color: string }>`
  ${containerMixin}
  color: ${props => props.$color};
  border: 1px solid ${props => props.theme.colors.purple};

  ${props => css`
    background: linear-gradient(
      180deg,
      ${props.theme.colors.timelineBlockType1GradientBottom} 0%,
      ${props.theme.colors.timelineBlockType1GradientTop} 100%
    );
  `}
`

const ContainerRampBack = styled.div<{ $color: string }>`
  ${containerMixin}
  color: ${props => props.$color};
  border: 1px solid ${props => props.theme.colors.sub100};

  ${props => css`
    background: linear-gradient(
      180deg,
      ${props.theme.colors.timelineBlockType2GradientBottom} 0%,
      ${props.theme.colors.timelineBlockType2GradientTop} 100%
    );
  `}
`

const ContainerUnknown = styled.div<{ $color: string }>`
  ${containerMixin}
  color: ${props => props.$color};
  border: 1px dashed ${props => props.theme.colors.main100};

  h3,
  h4 {
    color: ${props => props.theme.colors.dark80};
  }
`

const ColumnContainer = styled.div<{ $alignItems: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: ${props => props.$alignItems};
`

const RowContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 4px;
`

const Title = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
`

const Duration = styled.h4`
  font-size: 12px;
`

const Text = styled.p`
  font-size: ${props => (props.theme.isDesktop ? '12px' : '11px')};
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
`

const Note = styled.p`
  margin-top: 20px;
`

const AbsenceMainColor = {
  OnLeave: colors.light100,
  RampBack: colors.light100,
  Unknown: colors.dark60,
  PendingEndDate: colors.dark60,
  Default: colors.purple
}

export const dataAttrs = {
  title: () => 'absence-period-title',
  duration: () => 'absence-period-duration',
  startDate: () => 'absence-period-start-date',
  endDate: () => 'absence-period-end-date'
}

const AbsencePeriod = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { period } = props
  const { startDate, endDate } = props.period
  const absenceTitle = t(`manager.absenceType.${period.type}.title`)
  const absenceNote = i18n.exists(`manager.absenceType.${period.type}.note`)
    ? t(`manager.absenceType.${period.type}.note`)
    : null
  const isBlankEndDate = isBlankDate(endDate)

  const duration = isBlankEndDate
    ? t('common.dash')
    : getAbsencePeriodDurationString(period, t).humanReadableFullString

  const color = useMemo(
    () => AbsenceMainColor[period.type] || AbsenceMainColor.Default,
    [period.type]
  )
  const leftSide = useMemo(
    () => (
      <ColumnContainer $alignItems={'start'}>
        <Title data-testid={dataAttrs.title()}>{absenceTitle}</Title>
        <Duration data-testid={dataAttrs.duration()}>
          {period.isBeforeLeave
            ? t('common.time_left', { duration })
            : duration}
        </Duration>
      </ColumnContainer>
    ),
    [t, absenceTitle, duration, period]
  )

  const blockDuration = useMemo(
    () => getDaysBetweenDates(startDate, endDate),
    [startDate, endDate]
  )

  const endDateString = useMemo(
    () =>
      isBlankEndDate
        ? t('common.pending')
        : getTodayYesterdayOrDate(endDate, t),
    [isBlankEndDate, endDate, t]
  )

  const rightSide = useMemo(
    () => (
      <RowContainer>
        <ColumnContainer $alignItems={'end'}>
          <Text data-testid={dataAttrs.startDate()}>
            {getTodayYesterdayOrDate(startDate, t)}
          </Text>
          {blockDuration > 1 && (
            <Text data-testid={dataAttrs.endDate()}>{endDateString}</Text>
          )}
        </ColumnContainer>
        {blockDuration > 1 && <TimelineArrow color={color} />}
      </RowContainer>
    ),
    [t, blockDuration, startDate, endDateString, color]
  )
  const Container = useMemo(() => {
    switch (period.type) {
      case 'OnLeave':
        return ContainerOnLeave
      case 'RampBack':
        return ContainerRampBack
      case 'Unknown':
      case 'PendingEndDate':
        return ContainerUnknown
      default:
        return ContainerDefault
    }
  }, [period.type])

  return (
    <Container $color={color}>
      <ContentContainer>
        {leftSide}
        {rightSide}
      </ContentContainer>
      {absenceNote && <Note>{absenceNote}</Note>}
    </Container>
  )
})

AbsencePeriod.displayName = 'AbsencePeriod'

export default AbsencePeriod
