import { useEffect } from 'react'

export default (
  chartContainerRef: any,
  chartFunction: any,
  chartArguments: any
) => {
  useEffect(() => {
    const onResize = () => {
      try {
        while (chartContainerRef.current.firstChild) {
          chartContainerRef.current.removeChild(
            chartContainerRef.current.lastChild
          )
        }
      } catch (_e) {
        return
      }

      requestAnimationFrame(() => {
        chartFunction(chartArguments, chartContainerRef.current)
      })
    }

    window.addEventListener('resize', onResize)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [chartArguments, chartContainerRef, chartFunction])
}
