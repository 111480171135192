import moment, { Moment } from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import TileInfo from 'src/components/Dashboard/TileInfo'
import { DATE_FORMAT, isBlankDate } from 'src/utils/dateUtils'
import { DashboardProps } from '../../config'

const AbsenceStatus = React.memo((props: DashboardProps) => {
  const { t } = useTranslation()
  const { absence } = props
  const now = moment.utc()
  const currentPeriod = absence.info?.currentPeriod
  const nextPeriod = absence.info?.nextPeriod

  const absenceType = now.isBefore(absence.startDate)
    ? 'OnLeave'
    : nextPeriod?.type || 'AtWork'

  const formatDate = (m: Moment) => m.utc().format(DATE_FORMAT)

  let dateString = null
  if (now.isBefore(absence.startDate)) {
    dateString = formatDate(moment(absence.startDate))
  } else if (
    isBlankDate(moment(currentPeriod?.endDate)) ||
    isBlankDate(moment(nextPeriod?.endDate))
  ) {
    dateString = t('common.pending')
  } else if (nextPeriod?.startDate) {
    dateString = formatDate(moment(nextPeriod.startDate))
  } else {
    dateString = formatDate(moment(absence.endDate).add(1, 'day'))
  }

  const title = t(`manager.tiles.absenceStatus.${absenceType}`)

  return <TileInfo title={title}>{dateString}</TileInfo>
})

AbsenceStatus.displayName = 'AbsenceStatus'

export default AbsenceStatus
